import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (min-width: 800px) {
    max-width: 60%;
  }
`

const PrimaryImageContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 800px) {
    margin-bottom: 24px;
  }
`
const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  width: 100%;
  padding-x: 25px;
`
const Caption = styled.p`
  font-family: chivo-reg;
  align-items: center;
  text-align: center;
  font-size: 14px;
  margin-top: 9px;
`
const Description = styled.div`
  margin: 3rem;
`

const Index = () => {
  return (
    <Layout>
      <Container>
        <PrimaryImageContainer>
          <StaticImage
            src="../images/gallery/MixedMediaBirdIzabelle.jpeg"
            alt="Prying Bird by Izabelle Wilding"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
          <Caption>Prying Bird - Mixed Media on Canvas</Caption>
        </PrimaryImageContainer>
        <PrimaryImageContainer>
          <StaticImage
            src="../images/gallery/memories-of-mountain-road(main).JPG"
            alt="Mountain Road, Trapp by Izabelle Wilding"
            layout="fullWidth"
            loading="lazy"
          />
          <Caption>Mountain Road, Trapp - Acrylic on Canvas</Caption>
        </PrimaryImageContainer>
        <SubContainer>
          <StaticImage
            src="../images/gallery/memories-of-mountain-road(close).JPG"
            alt="Mountain Road, Trapp by Izabelle Wilding"
            placeholder="blurred"
            layout="fullWidth"
            loading="lazy"
          />
          <StaticImage
            src="../images/gallery/memories-of-mountain-road(side).JPG"
            alt="Mountain Road, Trapp by Izabelle Wilding"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
        </SubContainer>
      </Container>
      <PrimaryImageContainer>
        <StaticImage
          src="../images/gallery/echinacea(close).jpg"
          alt="Echinacea by Izabelle Wilding"
          layout="fullWidth"
          placeholder="blurred"
          loading="lazy"
        />
      </PrimaryImageContainer>
      <Container>
        <PrimaryImageContainer>
          <StaticImage
            src="../images/gallery/robin-duo-small.jpg"
            alt="Robin duo by Izabelle Wilding art"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
        </PrimaryImageContainer>
      </Container>
      <Container>
        <PrimaryImageContainer>
          <StaticImage
            src="../images/gallery/conception(mainclose).JPG"
            alt="Conception by Izabelle Wilding"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
          <Caption>Conception - Mixed Media on Canvas</Caption>
        </PrimaryImageContainer>
        <SubContainer>
          <StaticImage
            src="../images/gallery/conception(veryclose).jpg"
            alt="Conception by Izabelle Wilding"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
          <StaticImage
            src="../images/gallery/conception(sideview).jpg"
            alt="Conception by Izabelle Wilding"
            layout="fullWidth"
            placeholder="blurred"
            loading="lazy"
          />
        </SubContainer>
      </Container>
      <Container>
        <StaticImage
          src="../images/gallery/bristol-flower.JPG"
          alt="Bristol Flower by Izabelle Wilding"
          layout="fullWidth"
          placeholder="blurred"
          loading="lazy"
        />
        <Caption>Mixed media on Recycled Wood</Caption>
      </Container>
      {/* <Container>
        <StaticImage
          src="../images/gallery/echinacea(main).JPG"
          alt="Echinacea by Izabelle Wilding"
          layout="fullWidth"
          placeholder="blurred"
          loading="lazy"
        />
        <Caption>Echinacea - Oil on Canvas</Caption>
      </Container> */}
    </Layout>
  )
}

export default Index
